import type {Attendee, Context} from './attendee-container-machine';
import type {VerifyAccessCode, VerifyAccessCodeVariables} from './gql';
import {VerifyAccessCodeMutation} from './gql';

type VerifyAttendeProps = {
  /** Details provided by the browser client */
  context: Context;
  /** The id of the show to verify */
  showId: string;
  /** The published version of the site to verify against */
  siteVersionId?: string;
  /** The access code to verify */
  accessCode: string;
};

/**
 * Verify that an Attendee with the context and access code provided exists in a
 * particular show. Thrown errors will be caught by the state machine.
 * @returns details about the attendee for the verified access code
 * @throws when errors returned by GraphQL
 * @throws when no data from the `verifyAccessCode` mutation
 */
export async function verifyAccessCode({
  context,
  showId,
  accessCode,
}: VerifyAttendeProps): Promise<Attendee> {
  return context.client
    .mutate<VerifyAccessCode, VerifyAccessCodeVariables>({
      mutation: VerifyAccessCodeMutation,
      variables: {showId, accessCode},
      context: {showId: context.showId},
    })
    .then((result) => {
      const {errors, data} = result;

      // if an error message was returned, throw it
      if (typeof errors !== 'undefined') {
        throw new Error(errors.map((e) => e.message).join('  '));
      }
      // if no verification data was returned, throw an error
      else if (
        typeof data?.verifyAccessCode.sessionToken === 'undefined' ||
        typeof data?.verifyAccessCode.attendee === 'undefined' ||
        data.verifyAccessCode.sessionToken === null ||
        data.verifyAccessCode.attendee === null
      ) {
        throw new Error('No data from verify.');
      }
      // otherwise, return the attendee's data
      else {
        const result: Attendee = {
          ...data.verifyAccessCode.attendee,
          sessionToken: data.verifyAccessCode.sessionToken,
          attendeeTags: data.verifyAccessCode.attendeeTags,
          chatTokens: data.verifyAccessCode.chatTokens,
          attendeeType: 'access-code',
        };
        return result;
      }
    })
    .catch((reason) => {
      if (reason instanceof Error && reason.message.includes('has been used')) {
        throw new AccessCodeOverusedError(reason.message);
      }
      throw reason;
    });
}

/**
 * Custom `Error` implementation indicating a configuration issue.
 */
export class AccessCodeOverusedError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, AccessCodeOverusedError.prototype);
  }
}
